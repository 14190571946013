var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isCheckoutRedesignEnabled)?_c('CmsPage',{attrs:{"path":_vm.$route.path}}):_c('div',{staticClass:"vf-cart",class:{ 'vf-cart--core-redesign': _vm.isCoreRedesignEnabled }},[_c('Placement',{attrs:{"name":"main"}},[_c('VfContainer',{staticClass:"vf-cart__container",attrs:{"full-height":""}},[_c('DiscountNotification',{attrs:{"notification-persistent":""}}),_vm._v(" "),(_vm.customerFacingFlashes.length)?_c('CartNotifications'):_vm._e(),_vm._v(" "),_c('VfNotification',{attrs:{"visible":_vm.methodChangedNotificationDisplayed,"message":_vm.$t('cartNotifications.TopLevelPickupToSthTransition'),"html":"","show-close-button":false,"type":"danger"}}),_vm._v(" "),_c('CartTopPromoNotifications'),_vm._v(" "),_c('VfRow',{attrs:{"full-height":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12"}},[_c('CartTopSummary',{attrs:{"total-items":_vm.totalItems,"order-total":_vm.$formatPrice(_vm.totals.total, _vm.currency),"show-totals":_vm.isCartReady}}),_vm._v(" "),(_vm.isCartReady && !_vm.areProductsInCart)?_c('EmptyCart'):_vm._e()],1)],1),_vm._v(" "),(_vm.isAnythingInCart)?_c('VfRow',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCartReady),expression:"isCartReady"}],attrs:{"full-height":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"7","large":"8"}},[(_vm.areProductsInCart)?_c('CartProducts'):_vm._e(),_vm._v(" "),(!!_vm.outOfStockProducts.length)?_c('OutOfStockProducts',{staticClass:"checkout-large-only"}):_vm._e(),_vm._v(" "),(_vm.productsSavedForLater.length)?_c('SavedForLaterProducts',{staticClass:"checkout-large-only",attrs:{"products":_vm.productsSavedForLater}}):_vm._e(),_vm._v(" "),(
              _vm.cartProductRecommendationBeforeSummary &&
              _vm.dynamicSlots.belowCartItems &&
              _vm.dynamicSlots.belowCartItems.children.length
            )?_c('VfRow',[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12"}},[_c('CmsDynamicSlot',{attrs:{"data":_vm.dynamicSlots.belowCartItems,"context-key":_vm.contextKey,"name":_vm.dynamicSlots.belowCartItems.props.name}})],1)],1):_vm._e()],1),_vm._v(" "),_c('VfColumn',{attrs:{"small":"12","medium":"5","large":"4"}},[_c('PromoCodeSidebar'),_vm._v(" "),_c('OrderSummarySidebar'),_vm._v(" "),_c('CartCtaSidebar',{staticClass:"vf-cart__cart-cta-sidebar"}),_vm._v(" "),(_vm.theme.showInformationalSidebar && _vm.areProductsInCart)?_c('InformationalSidebar',{staticClass:"vf-cart__informational-sidebar"}):_vm._e(),_vm._v(" "),(
              _vm.dynamicSlots.belowOrderSummary &&
              _vm.dynamicSlots.belowOrderSummary.children.length
            )?_c('CmsDynamicSlot',{staticClass:"vf-cart__espot-below-order-summary",attrs:{"data":_vm.dynamicSlots.belowOrderSummary,"context-key":_vm.contextKey,"name":_vm.dynamicSlots.belowOrderSummary.props.name}}):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(_vm.isAnythingInCart)?_c('VfRow',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCartReady),expression:"isCartReady"}],attrs:{"full-height":""}},[_c('VfColumn',{attrs:{"small":"12"}},[(!!_vm.outOfStockProducts.length)?_c('OutOfStockProducts',{staticClass:"checkout-small-only",attrs:{"config":_vm.theme.productCard}}):_vm._e(),_vm._v(" "),(_vm.productsSavedForLater.length)?_c('SavedForLaterProducts',{staticClass:"checkout-small-only",attrs:{"products":_vm.productsSavedForLater,"config":_vm.theme.productCard}}):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(
          !_vm.cartProductRecommendationBeforeSummary &&
          _vm.dynamicSlots.belowCartItems &&
          _vm.dynamicSlots.belowCartItems.children.length
        )?_c('VfRow',{attrs:{"full-height":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12"}},[_c('CmsDynamicSlot',{attrs:{"data":_vm.dynamicSlots.belowCartItems,"context-key":_vm.contextKey,"name":_vm.dynamicSlots.belowCartItems.props.name}})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }