
































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  defineComponent,
  onMounted,
  ref,
  computed,
} from '@nuxtjs/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import {
  handlePreviewModeClientSide,
  transformSeoMetadata,
  useUserData,
} from '@/components/cms/cmsUtils';
import { usePdp } from '@vf/composables/src/useStaticLayout';
import { setPageTypeName } from '@/helpers';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useUserStore } from '@vf/composables/src/store/user';
import { States } from '@vf/api-contract';
import { useNotifyMe, useProduct } from '@vf/composables';
import { isCustomsProduct } from '@vf/composables/src/useCustoms/utils';
import useSignInToBuy from '@/components/smart/shared/composables/useSignInToBuy';
import { ProductInventoryState } from '@vf/api-client';

export default defineComponent({
  name: 'VansTemplate',
  components: {
    CmsDynamicSlot: () => import('@/components/cms/CmsDynamicSlot.vue'),
    VfShippingDestinations: () =>
      import('@/components/static/pdp/ShippingDestinations.vue'),
  },
  setup() {
    const { root } = useRootInstance();
    const { openModal } = useModal();
    const {
      allowApplePayOrderOnPDP,
      areMonetateExperiencesSetInCmsForTemplatizedPages,
      isBopis20Enabled,
      isVansPdpRedesignEnabled,
    } = useFeatureFlagsStore();

    const isApp = ref(null);

    const {
      contextKey,
      product,
      meta,
      dynamicSlots,
      monetateExperiencesPdp,
      addToCartBtnModalsProps,
      inventoryLoading,
      checkProductAndStates,
      productColorsKey,
      klarnaEnabled,
      seoWidgetRecommendationsEnabled,
      employeeConnected,
      onThirdPartyLoaded,
    } = usePdp(root, {
      handlePreviewModeClientSide,
      openModal,
      setPageTypeName,
      useUserData,
    });
    const { getSignInToBuyState } = useSignInToBuy(root, contextKey);
    const userStore = useUserStore(root);

    const showCustomsLogo = computed(() => {
      return isVansPdpRedesignEnabled && product.value?.dummyCustoms;
    });

    onMounted(() => {
      isApp.value = sessionStorage.getItem('app-webview') === 'true';
    });

    const {
      isAttributeOutOfStockStatus,
      checkNotifyMeEnabled,
      isSelectedProductOutOfStock,
      productColor,
    } = useProduct(root, contextKey);

    const { isUnavailableSizeHovered } = useNotifyMe(root, contextKey);

    const isNotifyMeEnabled = computed(() =>
      checkNotifyMeEnabled(isUnavailableSizeHovered.value)
    );

    const isShippingDestinationsVisible = computed(() => {
      if (!product.value) return false;
      return (
        !isNotifyMeEnabled.value &&
        ![
          isSelectedProductOutOfStock.value,
          !productColor.value,
          isAttributeOutOfStockStatus('size', product.value.size?.value),
          isAttributeOutOfStockStatus('length', product.value.length?.value),
          product.value?.productInventoryState ===
            ProductInventoryState.SoldOut,
          getSignInToBuyState(product.value, userStore.loyaltyEnrolled),
          isCustomsProduct(product.value),
        ].some(Boolean)
      );
    });

    const isApplePayEnabled = computed(
      () =>
        allowApplePayOrderOnPDP &&
        !isNotifyMeEnabled.value &&
        !getSignInToBuyState(product.value, userStore.loyaltyEnrolled)
    );

    return {
      areMonetateExperiencesSetInCmsForTemplatizedPages,
      contextKey,
      product,
      meta,
      dynamicSlots,
      monetateExperiencesPdp,
      addToCartBtnModalsProps,
      inventoryLoading,
      isBopis20Enabled,
      isShippingDestinationsVisible,
      checkProductAndStates,
      productColorsKey,
      klarnaEnabled,
      seoWidgetRecommendationsEnabled,
      employeeConnected,
      onThirdPartyLoaded,
      isVansPdpRedesignEnabled,
      isApp,
      showCustomsLogo,
      stateNotCustomsPDP: States.NotCustomsPDP,
      isApplePayEnabled,
    };
  },
  head() {
    return transformSeoMetadata(this);
  },
});
