import { Context, States } from '@vf/api-contract';
import {
  useFetch,
  useRoute,
  computed,
  nextTick,
  watch,
  onUnmounted,
  onMounted,
  Ref,
  inject,
  ref,
  ssrRef,
} from '@nuxtjs/composition-api';
import {
  isClient,
  scrollTo,
  computeStickyHeaderHeight,
} from '@vf/shared/src/utils/helpers';
import { getFinalPdoSearch } from '@vf/composables/src/useGtm/eventPropsHandlers/helpers';
import { apiClientFactory } from '@vf/api-client';
import { SearchType } from '@vf/composables/src/useSearch/types';
import { ComposableContext, ComponentInstance } from '../../../types';
import {
  useProduct,
  useProductInventory,
  useSectionNumeration,
  useSearch,
  useUrl,
  useNuanceChat,
  useFavorites,
  useSignInToStore,
  useTrackPage,
} from '../../../index';
import { useCmsRefStore } from '../../../store/cmsRef';
import { useStaticLayoutProps } from '../../../store/staticLayoutProps';
import type { MonetateExperienceProp } from '../../../store/staticLayoutProps';
import { createMetaTagObject } from '../../../utils/metaTags';
import {
  CmsSharedState,
  PageTypeName,
  InitializedApiClient,
} from '../../../useCms/types';
import { getPageCanonicalUrl } from '../../../useCms/utils/getPageCanonicalUrl';
import { getBreadcrumbs } from '../../../useCms/utils';
import { fetchPageContent } from '../../../useCms/dataFetcher/pages';
import { extractDynamicSlots } from '../../../useCms/utils/extractDynamicSlots';
import { useFeatureFlagsStore } from '../../../store/featureFlags';

const getPresets = (width = '', height = '') => {
  return width && height ? `wid=${width}&hei=${height}&qlt=95` : '';
};

type PdpDynamicSlot = {
  children?: unknown[];
  props?: {
    name?: string;
  };
};
type PdpDynamicSlots = {
  below?: PdpDynamicSlot;
  belowGrid?: PdpDynamicSlot;
  dynamicContent?: PdpDynamicSlot;
};

// skip content that include SeoRelatedWidget or ProductReviews
const skipComponent = ['shared/SeoRelatedWidget', 'pdp/ProductReviews'];
const getDynamicContentAndMonetate = (cmsBelow: PdpDynamicSlot) => {
  const cmsMonetate = [];
  let cmsDynamicContent = null;
  if (cmsBelow?.children?.length) {
    cmsDynamicContent = {
      ...cmsBelow,
      children: cmsBelow.children.filter((child) => {
        const dfsNavigation = [child];
        while (dfsNavigation.length) {
          const currNav = dfsNavigation.pop() as any;
          if (!currNav) continue;
          if (skipComponent.includes(currNav.component)) return false;
          // if is monetate
          if (
            currNav.props &&
            currNav.props.experienceId &&
            currNav.props.variants
          ) {
            cmsMonetate.push(currNav.props);
            return false;
          }

          currNav.children?.length && dfsNavigation.push(...currNav.children);
        }
        return true;
      }),
    };
  }

  return {
    cmsMonetate,
    cmsDynamicContent,
  };
};

export const usePdp = (
  instance: ComponentInstance,
  {
    handlePreviewModeClientSide,
    openModal,
    setPageTypeName,
    useUserData,
  }: Record<string, any>
) => {
  setPageTypeName(PageTypeName.PDP);

  const contextKey = Context.PageContent;
  const pageTypeName = PageTypeName.PDP;
  const route = useRoute();
  const locale = instance.$i18n.locale;
  const $themeConfig = instance.$themeConfig;
  const { dispatchPageGTMEvents } = useTrackPage(instance);
  const dynamicSlots: Ref<PdpDynamicSlots> = ref({} as PdpDynamicSlots);
  const dynamicContentBelow: Ref<PdpDynamicSlots['below'] | null> = ref(null);
  const { generateProductIdScene7 } = apiClientFactory(instance);

  let isLoadPageGTMEventsStarted = false;

  const cmsRefStore = useCmsRefStore();
  const {
    areMonetateExperiencesSetInCmsForTemplatizedPages,
    isBopis20Enabled,
  } = useFeatureFlagsStore();
  const staticLayoutProps = useStaticLayoutProps(instance);
  const { parseUrl, getProductCanonicalUrl } = useUrl(instance, contextKey);
  const {
    meta,
    product,
    getProductDetails,
    shouldShowPdpStickyHeader,
  } = useProduct(instance, contextKey);
  const { getProductInventory, inventoryLoading } = useProductInventory(
    instance,
    contextKey
  );
  const {
    autoCorrectQuery,
    isRedirected,
    queryString,
    pagination,
    pdoSearch,
    clearPdoSearch,
  } = useSearch(instance);
  const cmsApiClient: Ref<Ref<InitializedApiClient>> = inject('cmsApiClient');

  const fetchInventory = ssrRef(true, 'fetch-inventory-static-pdp');
  const {
    setNumberedSectionList,
    removeNumberedSection,
  } = useSectionNumeration(instance);
  const { getFavorites } = useFavorites(instance);
  const { employeeConnected } = useSignInToStore(instance);

  const monetateExperiencesPdp = ref([] as MonetateExperienceProp[]);

  const showProductAdditionalSizes = computed(() => {
    const productAdditionalSizesConfig =
      $themeConfig.staticPDP?.productAdditionalSizes;

    const attribute = product.value?.attributes?.find(
      (attr) => attr.code === productAdditionalSizesConfig?.role
    );

    return attribute?.options?.length > 1;
  });
  const productColorsKey = ref('');
  const clearNumberedSections = () => {
    [
      'additional-sizes-selector-on-static-pdp',
      'colors-selector-on-static-pdp',
      'sizes-selector-on-static-pdp',
      'quantity-selector-on-static-pdp',
    ].forEach((item) => removeNumberedSection(item));
  };
  const setNumberedSections = () => {
    const numberedSections = [];
    if (showProductAdditionalSizes.value) {
      numberedSections.push('additional-sizes-selector-on-static-pdp');
    }
    if (!product.value?.dummyCustoms) {
      numberedSections.push('colors-selector-on-static-pdp');
    }
    numberedSections.push('sizes-selector-on-static-pdp');
    numberedSections.push('quantity-selector-on-static-pdp');
    setNumberedSectionList(numberedSections);
  };
  const skipEvents = computed(
    () =>
      cmsRefStore.stateRedirectUrlExecuted ||
      cmsRefStore.canonicalRedirectUrlExecuted
  );
  const getPageMeta = (
    cmsRefValue: CmsSharedState,
    commercePageCanonicalUrl: string
  ) => {
    let productSpecificTags = [
      createMetaTagObject('og:productId', product.value?.id, true),
    ];
    if (product.value?.variant) {
      const { price, stock } = product.value.variant;
      const inStockLabel = stock?.inStock ? 'in stock' : 'out of stock';
      const { width, height }: any =
        cmsRefValue?.cmsSiteConfiguration?.commonConfig?.ogProductImageSizes ||
        {};

      const imageUrl = instance.$mediaUrlGenerator({
        pid: product.value
          ? product.value.isCustoms
            ? product.value.id
            : generateProductIdScene7(product.value, product.value.color?.value)
          : undefined,
        colorCode: '',
        colorName: product.value?.color?.label,
        shotType: instance.$env.IMAGES_SHOTTYPE,
        productName: product.value?.name,
        preset: getPresets(width, height),
      }) as string;
      productSpecificTags = [
        ...productSpecificTags,
        createMetaTagObject(
          'og:price:amount',
          price?.current?.toString(),
          true
        ),
        createMetaTagObject('og:price:currency', price?.currency, true),
        createMetaTagObject('og:availability', inStockLabel, true),
        createMetaTagObject('image', imageUrl),
        createMetaTagObject('og:image', imageUrl, true),
        createMetaTagObject('og:image:width', width, true),
        createMetaTagObject('og:image:height', height, true),
      ];
    }

    return {
      canonicalUrl: getPageCanonicalUrl(commercePageCanonicalUrl, instance),
      commerceTags: {
        ...Object.fromEntries(productSpecificTags.map((tag) => [tag.id, tag])),
        ...meta.value,
      },
      htmlTitle: meta.value?.title?.content || product.value?.name,
      htmlDescription: product.value?.description,
      openGraph: {
        ogUrl: product.value?.fullPageUrl,
        ogType: 'product',
        ogLocale: cmsRefValue?.cmsSiteConfiguration?.locale,
      },
      twitterCards: {
        ...(meta.value['og:description']?.content
          ? { tcDescription: meta.value['og:description']?.content }
          : null),
      },
      analyticsTags: {
        productId: product.value?.id,
      },
    };
  };

  const matchPdpUrl = new RegExp(`^/${locale}/pdp/`);
  const routePathWithoutPdp = route.value.path.replace(
    matchPdpUrl,
    `/${locale}/`
  );
  const { resourceType, resourceId } = parseUrl(
    routePathWithoutPdp,
    route.value.query as Record<string, string>
  );
  const loadStaticPdp = async () => {
    instance.$log.debug(
      '@composables/useStaticLayout/composables/usePdp::loadStaticPdp'
    );
    try {
      fetchInventory.value = true;
      cmsRefStore.$patch({ pageTypeName });
      nextTick(() => {
        isClient && window.$nuxt.$loading.start();
      });
      const fetchAfterRedirect =
        cmsRefStore.currentFetch?.resourceId === resourceId &&
        cmsRefStore.currentFetch?.resourceType === resourceType;
      if (fetchAfterRedirect) {
        cmsRefStore.$patch({
          stateRedirectUrlExecuted: false,
          canonicalRedirectUrlExecuted: false,
        });
        nextTick(() => {
          isClient && window.$nuxt.$loading.finish();
        });
        return;
      }

      inventoryLoading.value = true;
      const { variant, size, color } = route.value.query as Record<
        string,
        string
      >;
      const productResponse = await getProductDetails(resourceId, {
        isBackgroundRequest: false,
        loadImages: true,
        saveVariation: false,
        configuredVariant: variant,
        configuredSize: size,
        configuredColor: color,
      });

      productColorsKey.value = resourceId;

      const context: ComposableContext = { instance: instance, contextKey };
      const content = await fetchPageContent(
        'product',
        context,
        cmsRefStore.cmsSiteConfiguration,
        cmsApiClient.value.value,
        resourceId,
        ''
      );

      const breadcrumbs = getBreadcrumbs(
        content.content,
        'product',
        instance,
        cmsRefStore
      );
      cmsRefStore.$patch({ breadcrumbs });

      dynamicSlots.value = extractDynamicSlots(
        content,
        {
          siteConfiguration: cmsRefStore.cmsSiteConfiguration,
          cmsBaseUri: cmsRefStore.baseUri,
          requirements: cmsRefStore.requirements,
          context,
          pageTypeName: PageTypeName.PDP,
        },
        cmsRefStore.errors
      );

      dynamicContentBelow.value = null;

      const {
        cmsMonetate: cmsMonetateBelow,
        cmsDynamicContent: cmsDynamicContentBelow,
      } = getDynamicContentAndMonetate(dynamicSlots.value.below);
      dynamicContentBelow.value = cmsDynamicContentBelow;

      if (areMonetateExperiencesSetInCmsForTemplatizedPages) {
        dynamicSlots.value.dynamicContent?.children.forEach((child: any) => {
          monetateExperiencesPdp.value.push(child.props);
        });
        if (cmsMonetateBelow.length) {
          monetateExperiencesPdp.value.push(...cmsMonetateBelow);
        }
      } else {
        monetateExperiencesPdp.value = staticLayoutProps.monetateExperiencesPdp;
      }

      clearNumberedSections();
      setNumberedSections();
      cmsRefStore.$patch({
        meta: getPageMeta(
          cmsRefStore,
          getProductCanonicalUrl(productResponse)
        ) as CmsSharedState['meta'],
        currentFetch: {
          path: routePathWithoutPdp,
          resourceId,
          resourceType,
        },
      });
      nextTick(() => {
        isClient && window.$nuxt.$loading.finish();
      });

      if (cmsRefStore.stateRedirectUrlExecuted) {
        return;
      }
      if (cmsRefStore.isNotFound) {
        instance.$nuxt.error({ statusCode: 404, message: 'Not found' });
      }
    } catch (error) {
      instance.$log.error(
        `@composables/useStaticLayout/composables/usePdp::loadStaticPdp Error: ${error.message}`,
        {
          path: route.value.path,
          error,
        }
      );
      fetchInventory.value = false;
      instance.$nuxt.error({ statusCode: 404, message: 'Not found' });
    }
  };

  const loadPageGTMEvents = async () => {
    if (
      [!isClient, skipEvents.value, isLoadPageGTMEventsStarted].some(Boolean)
    ) {
      return;
    }
    isLoadPageGTMEventsStarted = true;
    await dispatchPageGTMEvents({
      contextKey,
      openModal,
      pageTypeName: { value: pageTypeName },
      trackAfterSSR: product,
      useUserData,
      hookAfterLoadPageData: clearPdoSearch,
      hookLoadPageDataExtendOverrideAttibutes: (overrideAttibutes) => {
        const {
          searchTerm,
          searchTermAdj,
          searchType,
          searchResults,
        } = getFinalPdoSearch(
          pdoSearch.value,
          pageTypeName,
          queryString.value,
          autoCorrectQuery.value,
          isRedirected.value,
          pagination.value.total
        );

        return searchType === SearchType.CLICK
          ? {
              ...overrideAttibutes,
              searchTerm,
              searchTermAdj,
              searchType,
              searchResults,
            }
          : overrideAttibutes;
      },
    });
    isLoadPageGTMEventsStarted = false;
  };
  loadPageGTMEvents();

  watch(route, async () => {
    await loadStaticPdp();
    loadPageGTMEvents();
    useNuanceChat(instance);
  });

  useFetch(async () => {
    await loadStaticPdp();
  });

  onMounted(() => {
    instance.$nuxt.$emit('customTriggerScroll');
    // Just for testing purpose. Remove this pieces before https://digital.vfc.com/jira/browse/GLOBAL15-86020 is done.
    const { favoriteStoreId } = route.value.query as Record<string, string>;
    const storeId =
      isBopis20Enabled && favoriteStoreId ? favoriteStoreId : undefined;
    if (fetchInventory.value) getProductInventory(resourceId, storeId);
    if (!employeeConnected.value) getFavorites();

    if (instance.$isPreview) {
      handlePreviewModeClientSide(instance, cmsRefStore.cmsSiteConfiguration);
    }
  });

  onUnmounted(() => {
    // Fix page freeze when navigating from PLP to same PDP
    cmsRefStore.$patch({
      currentFetch: { path: '', resourceId: '', resourceType: '' },
    });
  });

  const addToCartBtnModalsProps = {
    signInToBuy: staticLayoutProps.signInToBuy,
    loyaltyEnrollment: staticLayoutProps.loyaltyEnrollment,
  };

  watch(
    product,
    () => {
      clearNumberedSections();
      setNumberedSections();
    },
    { immediate: true }
  );

  const checkProductAndStates = (states: States[]) =>
    product.value && instance.$stateManagementChecker(states, contextKey);

  /* Wait for all thrid party components to load before scrolling to reviews */
  const hasReviewsHash = instance.$route.hash === '#pr-container';
  let totalComponents = monetateExperiencesPdp.value.length;
  totalComponents += instance.$config.OLAPIC_CREDENTIALS.apiKey ? 1 : 0;
  let loadedComponents = 0;

  const onThirdPartyLoaded = () => {
    loadedComponents++;

    if (loadedComponents === totalComponents && hasReviewsHash) {
      const reviews = document.getElementById('pr-container');

      if (reviews) {
        const headerHeight = computeStickyHeaderHeight(
          instance.$viewport?.isSmall,
          shouldShowPdpStickyHeader.value
        );

        instance.$nextTick(() => {
          scrollTo({ top: reviews.offsetTop - headerHeight });
        });
      }
    }
  };

  return {
    contextKey,
    product,
    meta: cmsRefStore.meta,
    cmsSiteConfiguration: cmsRefStore.cmsSiteConfiguration,
    dynamicContentBelow,
    dynamicSlots,
    monetateExperiencesPdp: computed(() => monetateExperiencesPdp.value),
    addToCartBtnModalsProps,
    inventoryLoading,
    checkProductAndStates,
    productColorsKey,
    klarnaEnabled: staticLayoutProps.klarnaEnabled,
    seoWidgetRecommendationsEnabled:
      staticLayoutProps.seoWidgetRecommendationsEnabled,
    employeeConnected,
    onThirdPartyLoaded,
  };
};
